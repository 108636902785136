
.app-container {
  display: flex;
  justify-content: center;
  background-color: rgb(253, 254, 254);
}

.app-desktop {
  background-color: white;
  width: 1100px;
  height: 100vh;
}

.app-mobile {
  max-width: 100%;
}

.app-logo {
  color: var(--primary-color);
}

.app-header {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-bottom: 40px;
}

.app-header-mobile {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-bottom: 10px;
}