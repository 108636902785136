
.backplate {
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 30px;
}

.backplate-mobile {
    padding: 16px 5px;
    width: 100%;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}